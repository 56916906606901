.sidebar {
  width: 70px;
  height: 100%;
  padding: 10px 10px;
}

.placeholder-icon {
  height: 40px;
  width: 40px;
  margin-bottom: 15px;
  border-radius: 3px;
  animation: shimmer 1.5s linear infinite;
  max-width: 1000px;
}

.placeholder {
  height: 12px;
  width: 100%;
  margin-bottom: 15px;
  border-radius: 3px;
  animation: shimmer 1.5s linear infinite;
  max-width: 100vw;
}

.placeholder-card {
  height: 60px;
}

.placeholder-tilebar {
  height: 60px;
  width: 100% !important;
  max-width: 100vw;
}

.placeholder--white {
  background-color: rgba(255, 255, 255, 0.1);
  background-image: linear-gradient(
    -90deg,
    rgba(255, 255, 255, 0) 0,
    rgba(255, 255, 255, 0.1) 50%,
    rgba(255, 255, 255, 0) 100%
  );
  background-repeat: no-repeat;
  background-size: 300px 100%;
  background-position: -300px 0;
}

.placeholder--dark {
  background-color: #f8f8f9;
  background-image: linear-gradient(-90deg, #f8f8f9 0, #eeeef0 50%, #f8f8f9 100%);
  background-size: 300px 100%;
  background-repeat: no-repeat;
  background-position: -300px 0;
}

.placeholder-short {
  width: 70%;
}

@keyframes shimmer {
  0% {
    background-position: -300px 0;
  }

  100% {
    background-position: 800px 0;
  }
}

.flex-container {
  display: flex;
  flex-direction: row;
  height: 100%;
}

.right-pane {
  flex: 1 1 auto;
  padding: 20px;
}

.page-loading {
  position: relative;
  width: 100%;
}
.page-loading__page-header {
  width: 100%;
  height: 120px;
  flex: 1 0 auto;
  position: relative;
  overflow: hidden;
  background-color: #ffffff;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
  z-index: 10;
}
.page-loading__content {
  position: relative;
  padding: 200px 16px 32px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
